const palette = {
  colorWhite: "rgba(255,255,255,1)",
  colorPurple: "#7752FF",
  colorBlueDark: "#100242",
  colorPurpleLight: "#F9F7FF",
  fontSemiSofia: "SofiaProSemiBoldAz, sans-serif",
  fontSofiaRegular: "SofiaProRegularAz, sans-serif",
  fontSofia: "SofiaProRegularAz, sans-serif",
  fontSofiaProBold: "SofiaProBoldAz, sans-serif",
  fontSofiaProLight: "SofiaProLightAz, sans-serif",
  fontSofiaProMedium: "SofiaProMediumAz, sans-serif",
  fontSofiaBold: "SofiaProBoldAz, sans-serif",
  lightBS: "rgba(0, 0, 0, 0.08) 0px 3px 10px",
}

export default palette
