import styled from "styled-components"
import palette from "../../../styles/styled-palette"

export const FooterContainer = styled.footer`
  max-width: 1900px;
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  padding: 5em 9em 15em 9em;
  grid-gap: 8em;

  .lapieza-info {
    .logo-container {
      max-width: 217px;
      margin-bottom: 2em;
      img {
        width: 100%;
        object-fit: scale-down;
      }
      @media (max-width: 1024px) {
        margin: auto;
        margin-bottom: 2em;
      }
    }
    font-size: 16px;
    line-height: 28px;
    font-family: ${palette.fontSofiaProLight};
    color: #555555;
  }
  .footer-section {
    .section-title {
      font-size: 18px;
      line-height: 24px;
      font-family: ${palette.fontSofiaProBold};
      color: #232323;
      margin-bottom: 20px;
    }
    .section-item {
      margin-bottom: 20px;
      a {
        font-size: 16px;
        line-height: 24px;
        font-family: ${palette.fontSofiaRegular};
        color: #232323;
        list-style: none;
        text-decoration: none;
      }
    }
  }

  .nav-options {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 8em;
    width: 100%;

    @media (max-width: 660px) {
      grid-auto-flow: unset;
      grid-template-columns: 1fr;
      justify-items: center;
      text-align: center;
    }
  }

  @media (max-width: 1024px) {
    grid-auto-flow: unset;
    grid-template-columns: 1fr;
    justify-items: center;
    text-align: center;
  }

  .footer-register-section {
    button, a {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.8em;
      line-height: 0.8em;
      color: #fdfdff;
      font-family: ${palette.fontSofia};
      background-color: ${palette.colorPurple};
      cursor: pointer;
      border: none;
      outline: none;
      border-radius: 28px;
      height: 2.3em;
      padding: 0 2em;
      margin-left: auto;
      width: fit-content;

      @media (max-width: 500px) {
        margin-left: 0;
        width: 100%;
      }
    }
  }
`
