const React = require('react');
const {GlobalStyles} = require('./src/styles');
const Layout = require('./src/components/Layout/layout').default;

exports.wrapRootElement = ({ element }) => (
  <Layout>
    <GlobalStyles />
      {element}
  </Layout>
)
