import React from "react"

import laPiezaLogo from "../../../images/logo-lapieza.png"
import { FooterContainer } from "./styled"

const Footer = () => {
  const footerItems = [
    {
      sectionTitle: "Company",
      items: [
        { text: "Nosotros", link: "https://empresas.lapieza.io/about-us" },
        { text: "FAQ'S", link: "https://lapieza.io/faqs" },
        { text: "Beneficios", link: "https://lapieza.io/beneficios" },
        { text: "Contacto", link: "https://empresas.lapieza.io/contact" },
      ],
    },
    {
      sectionTitle: "Solutions",
      items: [
        { text: "ATS", link: "https://empresas.lapieza.io/ats" },
        {
          text: "Headhunting",
          link: "https://empresas.lapieza.io/headhunting",
        },
        {
          text: "PreScreening",
          link: "https://empresas.lapieza.io/pre-screening",
        },
        { text: "LaPieza Academy", link: "https://academy.lapieza.io/" },
        { text: "Communities", link: "https://communities.lapieza.io/" },
      ],
    },
    {
      sectionTitle: "Resources",
      items: [{ text: "Blog", link: "https://blog.lapieza.io/" }],
    },
    {
      sectionTitle: "Legal",
      items: [
        {
          text: "T&C",
          link: "https://empresas.lapieza.io/terms-and-conditions",
        },
        {
          text: "Privacidad",
          link: "https://empresas.lapieza.io/privacy-policy",
        },
        { text: "Usabilidad", link: "https://empresas.lapieza.io/usability" },
        {
          text: "Neutralidad de carbono",
          link: "https://empresas.lapieza.io/pt/carbon-neutrality",
        },
      ],
    },
  ]

  return (
    <FooterContainer>
      <div className="lapieza-info">
        <div className="logo-container">
          <img src={laPiezaLogo} layout="responsive" alt="" />
        </div>
        <p className="lapieza-info">Cordoba 95, Roma Norte - CDMX, 06700</p>
        <p className="lapieza-info">
          Copyright © 2021 Talento Startup SAPI de CV
        </p>
        <p className="lapieza-info">pol@lapieza.io</p>
        <p className="lapieza-info">+52 55-6542-2798</p>
      </div>
      <div className="nav-options">
        {footerItems.map((section, index) => (
          <ul
            className={`footer-section ${
              [2, 3].includes(index) ? `column-${index}` : ""
            }`}
            key={section.sectionTitle}
          >
            <li className="section-title">{section.sectionTitle}</li>
            {section.items.map(item => (
              <li className="section-item" key={item.text}>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  {item.text}
                </a>
              </li>
            ))}
          </ul>
        ))}
      </div>
      <div className="footer-register-section">
        <a
          href="https://communities.lapieza.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Regístrate
        </a>
      </div>
    </FooterContainer>
  )
}

export default Footer
