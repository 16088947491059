import * as React from "react"
import styled from 'styled-components';
import Seo from './seo'
import PropTypes from "prop-types"

import "../../styles/fonts.css"
import "../../styles/_reset.css"

import Navbar from "./navbar"
import Footer from "./Footer"

import { StyledLayout } from "./styled"

const Main = styled.main`
  @media (max-width: 768px) {
    margin-top: 70px;
  }
`

const Layout = ({ children }) => {

  return (
    <StyledLayout>
      <Seo />
      <Navbar />
      <Main>{children}</Main>
      <Footer />
    </StyledLayout>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
