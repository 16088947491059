import React from "react"
import NavBarStyled, { DrawerMenu, MenuItem } from "./styled"
import { MenuOutline, CloseOutline } from "styled-icons/evaicons-outline"

import logo from "../../images/communities-logo.png"
import { Link } from "gatsby"
import { navigate } from "gatsby"

const Navbar = () => {
  const isBrowser = () => typeof window !== "undefined"

  const [openDrawer, setOpenDrawer] = React.useState(false)

  function handleOpenDrawerMenu() {
    setOpenDrawer(prevState => !prevState)
  }

  function navigateTo(route, id = null) {
    if (isBrowser()) {
      if (route === "/" || !window.location.href.includes(route)) {
        navigate(route)
      }

      if (id) {
        setTimeout(() => {
          const element = document.getElementById(id)
          function navigateWithHash() {
            const position = element.getBoundingClientRect()
            window.scrollTo(position.left, position.top + window.scrollY - 180)
          }
          if (element) {
            navigateWithHash(element)
          }
        }, 300)
      }

      if (openDrawer) {
        handleOpenDrawerMenu()
      }
    }
  }

  return (
    <>
      <NavBarStyled>
        <nav className="navbar">
          <button className="menu-drawer" onClick={handleOpenDrawerMenu}>
            {!openDrawer ? (
              <MenuOutline size={24} />
            ) : (
              <CloseOutline size={24} />
            )}
          </button>
          <Link to="/">
            <img id="logo" src={logo} alt="Logo de communities LaPieza" />
          </Link>
          <ul id="nav-content">
            <li className="nav-item">
              <a href="#aboutus">Nuestro Equipo</a>
            </li>
            <li>
              <a
                className="getEarlyAccess"
                href="https://communities.lapieza.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Regístrate
              </a>
            </li>
          </ul>
        </nav>
        <DrawerMenu openDrawer={openDrawer}>
          <MenuItem>
            <button onClick={() => navigateTo("/")}>Nuestro equipo</button>
          </MenuItem>
          <div className="login-section">
            <a href="https://communities.lapieza.io/">Regístrate ahora</a>
          </div>
        </DrawerMenu>
      </NavBarStyled>
    </>
  )
}

export default Navbar
