import styled, { css } from "styled-components"
import palette from "../../styles/styled-palette"

export const NavBarStyled = styled.div`
  width: 100%;
  z-index: 10000;
  grid-gap: 7em;

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
  }

  .navbar {
    padding: 0 10%;
    background-color: ${palette.colorPurple};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 10em;

    @media (max-width: 768px) {
      padding: 0;
      display: grid;
      grid-template-columns: 10em 1fr;
      grid-gap: 16px;
      align-items: center;
    }

    .menu-drawer {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      cursor: pointer;
      background-color: transparent;
      color: white;

      @media (min-width: 769px) {
        display: none;
      }
    }

    #logo {
      width: 30em;
      object-fit: scale-down;
      height: 100%;
    }

    #nav-content {
      display: grid;
      grid-template-columns: max-content max-content;
      grid-gap: 4em;
      justify-content: center;
      align-items: center;

      @media (max-width: 768px) {
        display: none;
      }

      .nav-item {
        a {
          font-family: ${palette.fontSofiaProMedium};
          color: ${palette.colorWhite};
          letter-spacing: 0.5px;
          font-size: 2.4em;
        }
      }

      .getEarlyAccess {
        border: none;
        outline: none;
        cursor: pointer;
        font-family: ${palette.fontSofiaProMedium};
        letter-spacing: 0.5px;
        font-size: 2.4em;
        background-color: ${palette.colorWhite};
        color: ${palette.colorPurple};
        border-radius: 25rem;
        padding: 12px 30px;

        @media (max-width: 767px) {
          font-size: 1.6em;
        }
      }
    }
  }
`

export const DrawerMenu = styled.div`
  height: 100%;
  width: ${({ openDrawer }) => (openDrawer ? css`100%` : css`0`)};
  position: fixed;
  z-index: 100;
  top: 70px;
  left: 0;
  background-color: white;
  overflow: hidden;
  transition: 0.5s;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  justify-items: center;
  align-content: flex-start;
  padding: ${({ openDrawer }) => (openDrawer ? css`50px 16px` : css`0`)};

  @media (min-width: 1201px) {
    display: none;
  }

  a,
  button {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    color: ${palette.colorBlack};
    font-family: ${palette.fontSofiaRegular};
    font-size: 16px;
  }

  li {
    margin: 0;
    padding: 0;
    display: ${({ openDrawer }) => (openDrawer ? css`block` : css`none`)};
  }

  .login-section {
    display: ${({ openDrawer }) => (openDrawer ? css`grid` : css`none`)};
    grid-template-columns: 1fr;
    grid-gap: 32px;
    justify-items: center;
    align-content: flex-start;
    margin-top: 200px;
    width: 100%;

    button, a {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2em;
      line-height: 0.8em;
      color: #fdfdff;
      font-family: ${palette.fontSofia};
      background-color: ${palette.colorPurple};
      cursor: pointer;
      border: none;
      outline: none;
      border-radius: 28px;
      height: 2.75em;
      padding: 0 1em;
      width: 100%;
      max-width: 400px;
    }
  }
`

export const size = {
  small: 400,
  medium: 480,
  mediumL: 960,
  large: 1140,
}

export const above = Object.keys(size).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${size[label]}px) {
      ${css(...args)}
    }
  `
  return acc
}, {})

export const MenuItem = styled.li`
  margin: 0;
  color: white;
  font-weight: 100;
  padding: ${({ margin }) => (margin ? "0 13px 0 13px" : "0 0 0 13px")};
  text-decoration: none;
  list-style: none;
  a {
    color: white;
    text-decoration: none;
  }
  & img {
    margin-right: 5px;
    width: 0.4rem;
  }
  ${above.medium`
    border-right: ${({ margin }) => (margin ? "1px solid white" : "")};
    padding: ${({ margin }) => (margin ? "0 26px 0 26px" : "0 0 0 26px")};
    & img{
      margin-right: 5px; 
      width: 0.7rem;
    }
  `}
`

export const ContactUsStyled = styled.main`
  width: 99vw;
  min-height: 90vh;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  #figureOne {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30vh;
  }
  #description {
    width: calc(50% - 20px);
    height: 80vh;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    h1 {
      font-family: ${palette.fontSofiaProBold};
      letter-spacing: 0.5px;
      font-size: 5em;
      width: 50%;
    }
    h2 {
      font-family: ${palette.fontSofiaRegular};
      letter-spacing: 0.5px;
      font-size: 2em;
      width: 50%;
    }
    h3 {
      font-family: ${palette.fontSofiaRegular};
      letter-spacing: 0.5px;
      font-size: 1.5em;
      width: 50%;
    }
  }
  #formOne {
    width: calc(50% - 20px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    form {
      width: 60%;
      height: 90%;
      background-color: ${palette.colorWhite};
      box-shadow: ${palette.lightBS};
      border-radius: 10px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 10px;
      label {
        font-family: ${palette.fontSemiSofia};
        letter-spacing: 0.5px;
        font-size: 1.3em;
        span {
          color: red;
        }
      }
      input,
      textarea {
        letter-spacing: 0.5px;
        font-size: 1.3em;
        padding: 7px 10px;
        border-radius: 10px;
        box-shadow: none;
        border: none;
        background-color: ${palette.colorPurpleLight};
      }
      textarea {
        min-height: 80px;
        max-height: 80px;
      }
      p {
        font-family: ${palette.fontSofiaRegular};
        letter-spacing: 0.5px;
        font-size: 1.3em;
      }
      #btnSubmit {
        background-color: ${palette.colorPurple};
        box-shadow: none;
        border: none;
        border-radius: 25em;
        padding: 10px;
        color: ${palette.colorWhite};
        font-family: ${palette.fontSofiaRegular};
        letter-spacing: 0.5px;
        font-size: 1.6em;
      }
    }
  }
`

export const StyledLayout = styled.main`
  font-size: 10px;
  max-width: 1920px;
  margin: auto;

  @media (max-width: 1632px) {
    font-size: 8.5px !important;
  }
  @media (max-width: 1440px) {
    font-size: 7.5px !important;
  }
`

export default NavBarStyled
